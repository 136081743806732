const Joi = window.joi;
const axios = window.axios;
const moment = window.moment;

const SCHEMA_SMALL = Joi.object({
  firstName: Joi.string().required().min(2),
  lastName: Joi.string().required().min(2),
  title: Joi.string().required(),
  restaurantName: Joi.string().required().min(2),
  phone: Joi.string().required().length(14),
  email: Joi.string().email({ tlds: {allow: false} }).required().label("Email"),
  state: Joi.string().length(2).required(),
  purchaseFromDistributor: Joi.string().required(),
});

const SCHEMA_BIG = Joi.object({
  firstName: Joi.string().required().min(2),
  lastName: Joi.string().required().min(2),
  title: Joi.string().required(),
  restaurantName: Joi.string().required().min(2),
  phone: Joi.string().length(14),
  email: Joi.string().email({ tlds: {allow: false} }).required().label('Email'),
	state: Joi.string().required().length(2),
	addressLine: Joi.string().required().min(3),
	city: Joi.string().required().min(2),
	zipcode: Joi.string().required().length(5),
  password: Joi.string().required().min(6),
  repeatPassword: Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`}),
  isGpo: Joi.boolean(),
  gpoName: Joi.string().when('isGpo', {is: false, then: Joi.allow('')}).when('isGpo', {is: true, then: Joi.string().required()}),
  terminationDate: Joi.date(),
  isDma: Joi.boolean(),
  nameOfCompanies: Joi.array().when('isDma', {is: true, then: Joi.array().min(1)}),
  termsAndConditions: Joi.bool().invalid(false).messages({'any.invalid':'Please check Terms And Conditions'}),
});

const BASE_URL = "https://api-cashback.diningalliance.com/";

const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Pragma": "no-cache"
  }
});

var leadId = null;

$(document).ready(function() {
  $(".phone").inputmask("(999) 999-9999");
  $(".zipcode").inputmask("99999");
  $(".select_reporting").val("1").trigger("change");

  $("#termination_date").datepicker({
    dateFormat: "mm-dd-yy"
  });

  $("#termination_date").datepicker("setDate", new Date());
  
  $(".select_title, .select_state, .select_do_you, .select_reporting, .select_is_gpo, .select_is_dma").select2({
    placeholder: "",
  });

  $(".select_google_places").select2({
    style: "display: none",
    placeholder: "",
    tags: true,
    ajax: {
      url: `${BASE_URL}places/search`,
      data: function (params) {
        var query = {
          input: params.term,
          country: 'us'
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.data.predictions.map(d => ({
            id: d.place_id,
            place_id: d.place_id,
            text: d.description,
            info: d
          }))
        };
      }
    }
  });

  $(".select_google_places").on("select2:select", function (e) {
    const elem = $(`.google_places_easy.row .select2-selection__rendered`);

    if (e.params.data.place_id) {
			apiInstance.get('places/details', {params: {placeId: e.params.data.place_id}}).then(({data}) => {
				const { result } = data.data;
				const { address_components, name, formatted_phone_number } = result;
				const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0] || address_components.filter(a => a.types[0] === 'premise')[0];
				const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
				const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
				const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
				const zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];
				const	payload = {
          addressLine: (`${(streetNumber && streetNumber.long_name) || ''}${street ? ' '+street.long_name : ''}`),
          state: usState ? {
            label: (usState && usState.long_name) || '',
            value: (usState && usState.short_name) || ''
          } : null,
          city: (city && city.long_name) || '',
          zipcode: (zipcode && zipcode.long_name.trim()) || '',
          restaurantName: name,
          showAddressDetails: !(streetNumber && street && city && usState && zipcode)
        };
        elem.prop("title", payload.restaurantName);
        elem.html(payload.restaurantName);
        $("input.restaurant_name").val(payload.restaurantName);
        $("input.city").val(payload.city);
        $("input.zipcode").val(payload.zipcode);
        $("input.address_line").val(payload.addressLine);
        $(".select_state").val(payload.state.value);
        $(".select_state").trigger("change");
        if (payload.showAddressDetails) {
          $(".google_places_details").removeClass("hide")
          $(".google_places_easy").addClass("hide")
        }
			}).catch(err => {
				console.error(err);
			});
		} else {
      $(".google_places_details").removeClass("hide")
      $(".google_places_easy").addClass("hide")
    }
  });

  $(".select_name_of_companies").select2({
    style: "display: none",
    placeholder: "",
    tags: true,
    minimumInputLength: 3,
    multiple: true,
    ajax: {
      url: `${BASE_URL}manufacturer`,
      data: function (params) {
        var query = {
          search: params.term,
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.data.map(d => ({
            id: d.id,
            text: d.nickname
          }))
        };
      }
    }
  });

  $(".select_gpo").select2({
    tags: true,
    createTag: function (params) {
      var term = $.trim(params.term);

      if (term === '') {
        return null;
      }

      return {
        id: term,
        text: term,
        newTag: true
      }
    }
  });

  $(".select_dropdown").one("select2:open", function(e) {
    $("input.select2-search__field").prop("placeholder", "Type to search");
  });

  $("#small_form").on("change", function(e) {
    const values = {
      firstName: $("#small_form input.first_name").val(),
      lastName: $("#small_form input.last_name").val(),
      title: $("#small_form .select_title option:selected").text(),
      restaurantName: $("#small_form input.restaurant_name").val(),
      email: $("#small_form input.email").val(),
      phone: $("#small_form input.phone").val(),
      state: $("#small_form .select_state").val(),
      purchaseFromDistributor: $(".select_do_you option:selected").text(),
    };
    const validation = SCHEMA_SMALL.validate(values, {abortEarly: false, errors: {wrap: {label: ""}}});
    const canSubmit = validation.error ? true : false;
    if ($("#small_form_button").html().trim() === "SUBMIT!") {
      $("#small_form_button").prop("disabled", canSubmit);
    }
  });

  $("#big_form").on("change", function(e) {
    const values = {
			firstName: $("#big_form input.first_name").val(),
			lastName: $("#big_form input.last_name").val(),
      title: $("#big_form .select_title option:selected").text(),
      restaurantName: $("#big_form input.restaurant_name").val(),
      phone: $("#big_form input.phone").val(),
      email: $("#big_form input.email").val(),
      state: $("#big_form .select_state").val(),
			addressLine: $("#big_form input.address_line").val(),
			city: $("#big_form input.city").val(),
			zipcode: $("#big_form input.zipcode").val(),
			password: $("#big_form input.password").val(),
			repeatPassword: $("#big_form input.repeat_password").val(),
      isGpo: $("#big_form .select_is_gpo option:selected").text() === "Yes" ? true : false,
      gpoName: $("#big_form .select_gpo option:selected").text(),
			terminationDate: $("#big_form #termination_date").val(),
      isDma: $("#big_form .select_is_dma option:selected").text() === "Yes" ? true : false,
      nameOfCompanies: $("#big_form .select_name_of_companies").val(),
      termsAndConditions: $(".terms_and_conditions input").is(":checked")
    };
    const validation = SCHEMA_BIG.validate(values, {abortEarly: false, errors: {wrap: {label: ""}}});
    const canSubmit = validation.error ? true : false;
    if ($("#big_form_button").html().trim() === "CREATE ACCOUNT") {
      $("#big_form_button").prop("disabled", canSubmit);
    }
  });

  $(".select_is_gpo").on("change", function(e) {
    if ($(".select_is_gpo").val() === "No") {
      $(".is_gpo_form").addClass("hide")
    } else if ($(".select_is_gpo").val() === "Yes") {
      $(".is_gpo_form").removeClass("hide")
    }
  });

  $(".select_is_dma").on("change", function(e) {
    if ($(".select_is_dma").val() === "No") {
      $(".is_dma_form").addClass("hide")
    } else if ($(".select_is_dma").val() === "Yes") {
      $(".is_dma_form").removeClass("hide")
    }
  });

  $("#small_form_button").click(function(e) {
    e.preventDefault();
    const params = {
      restaurantName: $("#small_form input.restaurant_name").val(),
      lastName: $("#small_form input.last_name").val(),
      firstName: $("#small_form input.first_name").val(),
      title: $("#small_form .select_title option:selected").text(),
      phone: $("#small_form input.phone").val(),
      email: $("#small_form input.email").val(),
      state: $("#small_form .select_state").val(),
      purchaseFromDistributor: $(".select_do_you option:selected").text()
    };
    $("#small_form_button").prop("disabled", true);
    $("#small_form_button").html("Loading...");
    apiInstance.post("auth/lead", params).then(({data}) => {
      window.history.pushState(null, "lead", "/?lead=1");
      leadId = data.data;
      $("#big_form").removeClass("hide");
      $("#small_form").addClass("hide");
      $("#signup-block col.finish").removeClass("hide");
      $("#signup-block col.start").addClass("hide");

      $("input.first_name").val(params.firstName);
      $("input.last_name").val(params.lastName);
      $(".select_title").val(params.title);
      $(".select_title").trigger("change");
      $("input.email").val(params.email);
      $("input.phone").val(params.phone);
      apiInstance.get("places/search", {
        params: {
          input: `${params.restaurantName}, ${params.state}, USA`,
          country: 'us'
        }
      }).then(({data}) => {
        const predictions = data.data.predictions;
        if (predictions.length > 0) {
          const p = predictions[0];
          $(".select_google_places").trigger({
            type: "select2:select",
            params: {
              data: {
                id: p.place_id,
                place_id: p.place_id,
                text: p.description,
                info: p
              }
            }
          });
        } else {
          $(".select_state").val(params.state);
          $(".select_state").trigger("change");
          $("input.restaurant_name").val(params.restaurantName);
          $(".google_places_details").removeClass("hide")
          $(".google_places_easy").addClass("hide")
        }
      }).catch(function(error) {
        console.error(error);
      });
    }).catch(function(error) {
      console.error(error);
      $("#small_form_button").html("Something wen't wrong");
    });
  });

  $("#big_form_button").click(function (e) {
    e.preventDefault();
    $("#big_form_button").prop("disabled", true);
    $("#big_form_button").html("Loading...");

    const createAccount = (smartyLocations) => {
      const isGpo = $("#big_form .select_is_gpo option:selected").text() === "Yes" ? true : false;
      const isDma = $("#big_form .select_is_dma option:selected").text() === "Yes" ? true : false;
      const canvas = document.getElementById("newSignature");// save canvas image as data url (png format by default)
      const dataURL = canvas.toDataURL("image/png");
      const params = {
        contactName: $("#big_form input.first_name").val() + ":" + $("#big_form input.last_name").val(),
        title: $("#big_form .select_title option:selected").text(),
        businessName: $("#big_form input.restaurant_name").val(),
        businessAddress: smartyLocations[0].address,
        businessCity: smartyLocations[0].city,
        businessState: smartyLocations[0].state,
        businessPostcode: smartyLocations[0].postcode,
        businessStateName: $("#big_form .select_state option:selected").text(),
        phoneNumber: $("#big_form input.phone").val(),
        email: $("#big_form input.email").val(),
        password: $("#big_form input.password").val(),
        passwordRepeat: $("#big_form input.repeat_password").val(),
        emailRepeat: $("#big_form input.email").val(),
        referredBy: 'CP-002739',
        referredName: 'Marketing User',
        daSalesRep: 'Inside Sales',
        siteUrl: "https://mydiningalliance.com/",
        siteFullUrl: window.location.href,
        companyId: 1,
        brandCode: "da",
        brand: "Dining Alliance",
        totalReportingLocations: parseInt($("#big_form .select_reporting").val()),
        sfLeadId: leadId,
        lop: {
          isReinhartAgreement: 0,
          isDistributorManufacturerAgreement: isDma ? 1 : 0,
          legalName: $("#big_form input.restaurant_name").val(),
          signedText: $("#big_form input.first_name").val() + " " + $("#big_form input.last_name").val(),
          signature: dataURL,
          isFsr: 1,
          isGpo: isGpo ? 1 : 0,
          gpoName: isGpo ? ($("#big_form .select_gpo option:selected").text()) : '',
          terminationDate: $("#big_form #termination_date").val(),
          nameOfCompanies: isDma ? $("#big_form .select_name_of_companies option:selecteda").text() : ''
        },
        purchaseFromDistributor: $(".select_do_you option:selected").text(),
        segment: null,
        subsegment: null
      };
      apiInstance.post("auth/sign-up-simple", params).then(({data}) => {
        $("#thankyou").removeClass("hide");
        $("#big_form").addClass("hide");
      }).catch(err => {
        console.error(err);
        $("#big_form_button").html("Something wen't wrong");
      });
    };

    const smartifyAddress = () => {
      const streets = [{
        address: $("#big_form input.address_line").val(),
        city: $("#big_form input.city").val(),
        state: $("#big_form .select_state").val(),
        postcode: $("#big_form input.zipcode").val()
      }];
      apiInstance.post('places/smarty', {
        streets,
        country: 'us'
      }).then(({data}) => {
        const smartyLocations = data.data.map(loc => ({
          ...loc,
          postcode: loc.postcode.trim()
        }));
        createAccount(smartyLocations);
      }).catch(err => {
        console.error(err);
        $("#big_form_button").html("Something wen't wrong");
      });
    };

    smartifyAddress();
  });

  $(".hide_details").click(function(e) {
    e.preventDefault();
    $(".google_places_details").addClass("hide")
    $(".google_places_easy").removeClass("hide")
  });

  $(".show_details").click(function(e) {
    e.preventDefault();
    $(".google_places_details").removeClass("hide")
    $(".google_places_easy").addClass("hide")
  });

  $(".scroll_to_form").click(function(e) {
    const $toElement      = $('#signup-block');
    const $focusElement   = $("input.first_name");
  
    $('html, body').animate({
      scrollTop: $($toElement).offset().top + 2
    }, 200);
    
    if ($focusElement) $($focusElement).focus();
  });

  $( window ).resize(function() {
    var canvas = document.getElementById("newSignature");
    var context = canvas.getContext("2d");
    canvas.width = $("#canvas").width();
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#fff";
    context.strokeStyle = "#444";
    context.lineWidth = 1.5;
    context.lineCap = "round";
    context.fillRect(0, 0, canvas.width, canvas.height);
  });
  
  $('.mfr-logos').slick({
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    touchMove: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
  });
});
